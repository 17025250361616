exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-blog-author-tsx": () => import("./../../../src/templates/blog/blogAuthor.tsx" /* webpackChunkName: "component---src-templates-blog-blog-author-tsx" */),
  "component---src-templates-blog-blog-category-tsx": () => import("./../../../src/templates/blog/blogCategory.tsx" /* webpackChunkName: "component---src-templates-blog-blog-category-tsx" */),
  "component---src-templates-blog-blog-home-tsx": () => import("./../../../src/templates/blog/blogHome.tsx" /* webpackChunkName: "component---src-templates-blog-blog-home-tsx" */),
  "component---src-templates-blog-blog-post-tsx": () => import("./../../../src/templates/blog/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-blog-post-tsx" */),
  "component---src-templates-buy-buy-thank-you-tsx": () => import("./../../../src/templates/buy/buyThankYou.tsx" /* webpackChunkName: "component---src-templates-buy-buy-thank-you-tsx" */),
  "component---src-templates-buy-buy-tsx": () => import("./../../../src/templates/buy/buy.tsx" /* webpackChunkName: "component---src-templates-buy-buy-tsx" */),
  "component---src-templates-contact-contact-submit-tsx": () => import("./../../../src/templates/contact/contactSubmit.tsx" /* webpackChunkName: "component---src-templates-contact-contact-submit-tsx" */),
  "component---src-templates-contact-contact-tsx": () => import("./../../../src/templates/contact/contact.tsx" /* webpackChunkName: "component---src-templates-contact-contact-tsx" */),
  "component---src-templates-download-download-tsx": () => import("./../../../src/templates/download/download.tsx" /* webpackChunkName: "component---src-templates-download-download-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-t-and-c-tsx": () => import("./../../../src/templates/tAndC.tsx" /* webpackChunkName: "component---src-templates-t-and-c-tsx" */)
}

